@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Nunito:ital,wght@0,300;0,400;0,600;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecef ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.snaps-contaier {
  overflow-y: auto;
  // height: calc(100vh - 15px);
  padding: 20px 0px;
}
.snaps-contaier .card {
  // min-height: 90px;
  // max-height: 90px;
  padding: 10px;
  justify-content: space-between;
  color: black;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  background-color:white;
  border-radius: 5px;
}

.snaps-contaier .card div {
  text-align: left;
  text-transform: capitalize;
  font-size: 20px; 
}

.snaps-contaier .card h3 {
  text-align: right;
  font-weight: 400;
}

.tbl-container {
  padding: 0px!important;
  box-shadow: rgba(0, 0, 0 , 0.15) 0px 5px 5px;
  padding: 40px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #FFF;
}

.tbl-search-container {
  padding: 10px;
  box-shadow: rgba(0, 0, 0 , 0.15) 0px 5px 5px; 
  background: #FFF;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tbl-wrapper {
  padding: 20px 0px;
}


.table {
  border-spacing: 0px;
}

.react-bootstrap-table {
  margin-top: 0px;
}
.react-bootstrap-table .table {
  border-collapse: inherit;
  table-layout: auto;
}
.react-bootstrap-table .table thead tr {
  background-color: #fff;
}
.react-bootstrap-table .table thead th:nth-child(2){
  text-align: left;
}
.react-bootstrap-table .table thead th {
  color: #666;
  font-size: 16px;
  border: none;
}
.react-bootstrap-table .table tbody tr {
  background-color: #f2f2f2;
}

.react-bootstrap-table .table tbody td {
  color: #666;
  font-size: 14px; 
}
.react-bootstrap-table .table tbody td:nth-child(2){
  text-align: left;
  width: 35%;
  span{
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
.react-bootstrap-table .table tbody td .table{
  margin-bottom: 0px;
  tbody{
    tr:last-of-type{
      td{
        border-bottom-width: 0px;
      }
    }
  }
}

.react-bootstrap-table .table tbody td .table tbody tr td:nth-child(2){
  text-align: left;
  width: 35%;
}
.react-bootstrap-table .table tbody td {
  width: 17%;
}
.react-bootstrap-table .table tbody td:nth-child(3),
.react-bootstrap-table .table tbody td:nth-child(4),
.react-bootstrap-table .table tbody td:nth-child(5),
.react-bootstrap-table .table tbody td:nth-child(6){
  width: 15%
}

 

.react-bootstrap-table .table tbody td .table tbody tr td:nth-child(3),
.react-bootstrap-table .table tbody td .table tbody tr td:nth-child(4),
.react-bootstrap-table .table tbody td .table tbody tr td:nth-child(5),
.react-bootstrap-table .table tbody td .table tbody tr td:nth-child(6)
{
  width: 15%;
}
.react-bootstrap-table .table tbody td:nth-child(1){
  width: 20px;
}

.row-expand-slide-appear-done .row-expansion-style {
  background-color: #fff;
}
.row-expand-slide-appear-done .row-expansion-style .table tbody tr td {
  background-color: #fff;
}
.expand-cell-header {
  pointer-events: none;
} 



.expanding-parent-row {
  width: 97vw;
  overflow-x: scroll;
  white-space: nowrap;
  background: "red";
}
._loading_overlay_overlay{
  background: rgba(1, 0, 0, 0.0) !important;
  /* color: black !important; */
}

.login-main-container {
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #656d9a;
  border: 1px solid #707070;
  .LoginForm {
    background-color: #fff;
    width: 600px;
    min-height: 400px;
    padding: 20px;
    border-radius: 0px;
    text-align: left;
    border-bottom: solid 8px #15324f;
    box-shadow: 5px 7px 10px 0px rgba(0, 0, 0, 0.3);
    h1 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
      text-align: center;
      .logo {
        height: 40px;
      }
    }
    form {
      label {
        color: #303e67;
        font-size: 12px;
      }
      .form-control {
        border-radius: 0px;
      }
      .btn-primary {
        background-color: #4a7cdd;
      }
    }
  }
  .ForgetForm {
    background-color: #fff;
    width: 415px;
    min-height: 200px;
    padding: 20px;
    border-radius: 0px;
    text-align: left;
    border-bottom: solid 8px #15324f;
    box-shadow: 5px 7px 10px 0px rgba(0, 0, 0, 0.3);
    h1 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
      text-align: center;
    }
    form {
      label {
        color: #303e67;
        font-size: 12px;
      }
      .form-control {
        border-radius: 0px;
      }
      .btn-primary {
        background-color: #4a7cdd;
      }
    }
  }
  .password-info{
    position: absolute;
    right: 10px;
    top: 36px; 
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover{
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}

.tooltip-sidebar.fade.show {
  transition: none !important;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #dce9f7;
}

.page-item .page-link {
  color: #25517e;
}

.page-item .page-link :hover {
  color: #25517e;
}

.page-item.active .page-link{
  background: #15324f;
  border:#15324f ;
}

.btn-secondary  {
    color: #fff;
    background-color: #15324f;
    border-color: #15324f;
}

.btn-secondary:hover  {
  color: #fff;
  background-color: #15324f;
  border-color: #15324f;
}

.react-bootstrap-table-pagination{
  text-align: left;
}

.uploaded-orders {
  background-color: #dcac1d
}

.completed-orders {
  background-color: green
}

.inprocess-orders {
  background-color: #4dcdd1
}

.billable-orders {
  background-color: #fd7e14
}

.css-1s2u09g-control {
  cursor: pointer !important;
}
.css-14el2xx-placeholder, .css-26l3qy-menu{
  text-align: left; 
}

.css-qc6sy-singleValue{
  text-align: left;
}

.hello {
  z-index: 30;
  background-color: #f8fbfb;
}

.cards {
  border: 1px dotted #ccc;
  padding: 7px;
  border-radius: 10px;
}

.logo {
  height: auto;
  width: 150px;
}

.header-wrapper {
  background: #15324f;
  height: 60px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  background: #FFF;
}

.img-profile {
  height: 20px;
}

.btn-user {
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e2e2e2;
}

.rm-underline{
  text-decoration: none;
}

.horizontal-line {
  border: none;
  height: 1px;
  background-color: black; /* Adjust color as needed */
}

.sticky-header{
  position: sticky;
  z-index: 9;
  background-color: #FFF; 
  top: 0px;
  z-index: 1;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  //  border-right: 0.3em solid transparent; 
  // border-bottom: 0;
  // border-left: 0.3em solid transparent;
  border-right: none; 
  border-bottom: 0;
  border-left: none;
}

.react-bootstrap-table{
  overflow: auto;
  max-height: 76vh;
  min-height: 10vh;
}

.row .react-bootstrap-table-pagination{
  // margin-top: 15px;
  padding: 10px;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer !important;
}

// Define your CSS classes
.table-wrapper-class {
  border: none;
}

.header-no-border {
  border-bottom: none !important;
}

.body-no-border {
  border-bottom: none !important;
}

.row-classes {
  border-bottom: none !important;
}


.table-bordered tr:nth-child(odd) {
  background-color: #fff !important; /* Change background color for odd rows */
}

.table-bordered tr:nth-child(even) {
  background-color: #f8f9fa  !important; /* Change background color for even rows */
}

.nested-table .react-bootstrap-table-row .table-bordered tr:nth-child(odd) {
  background-color: #fff !important; /* Change background color for odd rows */
}

.nested-table .react-bootstrap-table-row .table-bordered tr:nth-child(even) {
  background-color: red#fff !important; /* Change background color for odd rows */
}

.custom-row:hover {
  // pointer-events: none; /* Disable hover effect on nested expanded rows */
}


.cell-classes {
  border-right: 2px solid #e9ecef !important
}

.align-left {
  text-align: left !important;
}

.custom-header th {
  background-color: white; 
  color: #fff; 
}

.reset-expansion-style .row-expand-slide-appear-done {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.reset-expansion-style .row-expand-slide-appear-done .row-expansion-style{
  border-radius: 5px;
}

.file-table td {
  border: none;
}

